import { Container, Button, Grid, Paper, Typography } from "@mui/material";
import { Libraries, LoadScript } from "@react-google-maps/api";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";

const placesLibs: Libraries = ["places"];
const googleMapsApiKey =
  process.env.REACT_APP_ENV === "production"
    ? "AIzaSyBF90b6SXXIUcPhJAIHHM69emhKfX0JvLo"
    : "AIzaSyBF90b6SXXIUcPhJAIHHM69emhKfX0JvLo";

const AdminPage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Paper style={{ padding: "16px", marginBottom: "16px" }}>
        <Typography variant="h4" gutterBottom>
          Admin Dashboard
        </Typography>
        {process.env.REACT_APP_ENV === "production" && (
          <Typography variant="body1" color="red" gutterBottom>
            PRODUCTION
          </Typography>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/admin/events")}
            >
              Go to Events
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/admin/notificationtool")}
            >
              Go to Notification Tool
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const AdminPageWrapper = () => (
  <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={placesLibs}>
    <AdminPage />
  </LoadScript>
);

export default AdminPageWrapper;
