import { format, toZonedTime } from "date-fns-tz";
import { EventPost } from "./Events";

// Utility function to convert Firestore timestamp to JavaScript Date
const firestoreTimestampToDate = (timestamp: {
  _seconds: number;
  _nanoseconds: number;
}): Date => {
  return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
};

function removeAlphaChannel(color: string): string {
  const regex = /^#FF([0-9A-Fa-f]{6})$/;
  const match = regex.exec(color);
  if (match) {
    return `#${match[1]}`; // Return the RGB part
  }
  return color; // Return original color if it doesn't match
}

const convertColor = (color: string): string => {
  if (color.startsWith("#")) {
    return removeAlphaChannel(color);
  }
  if (color.startsWith("0x")) {
    return `#${color.slice(2)}`;
  }
  return color;
};

const convertDeltaColors = (delta: any): any => {
  return delta?.map
    ? delta?.map((op: any) => {
        if (op.attributes) {
          if (op.attributes.color) {
            op.attributes.color = convertColor(op.attributes.color);
          }
          if (op.attributes.background) {
            op.attributes.background = convertColor(op.attributes.background);
          }
        }
        return op;
      })
    : delta;
};

const formatFirebaseTimestamp = (
  timestamp: { _seconds: number; _nanoseconds: number },
  timeZone: string
): string => {
  const date = firestoreTimestampToDate(timestamp);
  const zonedDate = toZonedTime(date, timeZone);
  return format(zonedDate, "MM/dd/yyyy hh:mm a", { timeZone });
};

const toFormDate = (date: Date): string => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(date, timeZone);
    return format(zonedDate, "yyyy-MM-dd'T'HH:mm", { timeZone });
  } catch (error) {
    return date as unknown as string;
  }
};

const emptyPost: EventPost = {
  id: "",
  title: "",
  content: "",
  place: "",
  placeAddress: "",
  imageUrl: "",
  dateCreated: new Date(),
  startTime: toFormDate(new Date()),
  endTime: "",
  userId: "",
  isEvent: true,
  location: {
    geohash: "",
    geopoint: {
      _latitude: 0,
      _longitude: 0,
    },
  },
};

const formatPost = (post: EventPost): EventPost => {
  if (post.startTime) {
    post.formattedStartTime = formatFirebaseTimestamp(
      post.startTime as any,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    post.startTime = firestoreTimestampToDate(post.startTime as any);
  }
  if (post.endTime) {
    const endTimeDate = new Date(post.endTime);
    if (endTimeDate.getFullYear() > 2100) {
      post.formattedEndTime = "";
      post.endTime = null;
    } else {
      post.formattedEndTime = formatFirebaseTimestamp(
        post.endTime as any,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      post.endTime = firestoreTimestampToDate(post.endTime as any);
    }
  }

  if (post?.content?.includes('"insert"')) {
    post.content = JSON.parse(post.content);
    post.content = convertDeltaColors(post.content);
  }
  return post;
};

export {
  convertDeltaColors,
  formatFirebaseTimestamp,
  toFormDate,
  firestoreTimestampToDate,
  emptyPost,
  formatPost,
};
