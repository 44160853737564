import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Container,
  Box,
  TextField,
  Hidden,
  useMediaQuery,
  Link,
  Dialog,
  IconButton,
  DialogContent,
  Snackbar,
  Alert,
  AlertColor,
  CircularProgress,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  ChatOutlined,
  CalendarMonthOutlined,
  PlaceOutlined,
  Close,
} from "@mui/icons-material";
import ShareButtons from "../Share";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import DisclaimerDialogContent from "../DisclaimerDialogContent";
import Footer from "../Footer";
import ActivePreventionDialog from "../DisclaimerDialogContent copy";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminPage from "./Admin";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import SignIn from "../SignIn";
import EventsPage from "./Admin/Events";
import NotificationToolPage from "./Admin/NotificationTool";

const AuthContext = createContext<any>(null);

const AuthProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const PrivateRoute = ({ children }: any) => {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? children : <Navigate to="/signin" />;
};

function App() {
  return (
    <AuthProvider>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Home />} />
        <Route
          path="admin"
          element={
            <PrivateRoute>
              <AdminPage />
            </PrivateRoute>
          }
        />
        <Route
          path="admin/events"
          element={
            <PrivateRoute>
              <EventsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="admin/notificationtool"
          element={
            <PrivateRoute>
              <NotificationToolPage />
            </PrivateRoute>
          }
        />
        <Route path="/signin" element={<SignIn />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;

function Home() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#046E00",
      },
    },
    typography: {
      fontFamily: "Roboto",
    },
    components: {
      MuiSnackbar: {
        styleOverrides: {
          root: {
            zIndex: 99999999999,
          },
        },
      },
    },
  });
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isXLargeUp = useMediaQuery(theme.breakpoints.up(2200));

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showAndroidMessage, setShowAndroidMessage] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/-/g, "");
    if (rawValue.length <= 3) {
      setPhoneNumber(rawValue);
    } else if (rawValue.length <= 6) {
      setPhoneNumber(`${rawValue.slice(0, 3)}-${rawValue.slice(3)}`);
    } else {
      setPhoneNumber(
        `${rawValue.slice(0, 3)}-${rawValue.slice(3, 6)}-${rawValue.slice(
          6,
          10
        )}`
      );
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setSubmitting(true);
    event.preventDefault();
    const isValidPhoneNumber = /^\d{3}-\d{3}-\d{4}$/.test(phoneNumber);
    setPhoneNumberError(!isValidPhoneNumber);
    if (isValidPhoneNumber) {
      try {
        setSnackbarSeverity("success");
        setSnackbarText(
          "You have successfully submitted your phone number and will receive a text when Vicinity launches. Thank you!"
        );
        await addPhoneNumber(phoneNumber);
        setFormModalOpen(false);
        setPhoneNumber("");
        setSnackbarOpen(true);
      } catch (error) {
        setPhoneNumber("");
        setFormModalOpen(false);
        setSnackbarSeverity("error");
        setSnackbarText(
          "There was an error submitting your phone number. Please try again."
        );
        setSnackbarOpen(true);
        console.error("Error adding phone number:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const [formModalOpen, setFormModalOpen] = useState(false);
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);
  const [activePrevntionDialogOpen, setActivePrevntionDialogOpen] =
    useState(false);
  useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>("success");

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 600px)").matches;
    if (isMobile) {
      const timer = setTimeout(() => {
        setFormModalOpen(true);
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleFormModalOpen = () => {
    setFormModalOpen(true);
    setShowAndroidMessage(true);
  };

  const handleFormModalClose = () => {
    setFormModalOpen(false);
    setShowAndroidMessage(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={disclaimerModalOpen}
        onClose={() => {
          setDisclaimerModalOpen(false);
        }}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        sx={{
          ".MuiPaper-root": {
            borderRadius: "15px",
            border: "1px solid black",
            minHeight: "400px",
            minWidth: "320px",
            width: "450px",
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => {
            setDisclaimerModalOpen(false);
          }}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 15,
            top: 8,
            zIndex: 9999,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <DisclaimerDialogContent />
        </DialogContent>
      </Dialog>
      <Dialog
        open={activePrevntionDialogOpen}
        onClose={() => {
          setActivePrevntionDialogOpen(false);
        }}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        sx={{
          ".MuiPaper-root": {
            borderRadius: "15px",
            border: "1px solid black",

            width: "450px",
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => {
            setActivePrevntionDialogOpen(false);
          }}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 15,
            top: 8,
            zIndex: 9999,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <ActivePreventionDialog />
        </DialogContent>
      </Dialog>
      {(!isMediumUp || showAndroidMessage) && (
        <Dialog
          open={formModalOpen}
          onClose={handleFormModalClose}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          sx={{
            ".MuiPaper-root": {
              borderRadius: "15px",
              border: "1px solid black",
              minHeight: "400px",
              minWidth: "320px",
              width: "450px",
            },
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleFormModalClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 15,
              top: 8,
              zIndex: 9999,
            }}
          >
            <Close />
          </IconButton>
          {!showAndroidMessage ? (
            <DialogContent>
              <Typography variant="h6">Get the Vicinity app on...</Typography>
              <Grid container xs={12}>
                <Grid
                  container
                  item
                  spacing={2}
                  padding="20px"
                  textAlign={"center"}
                  alignItems={"center"}
                >
                  <Grid item xs={12} paddingBottom={"20px"} marginTop={"40px"}>
                    <a href="https://apps.apple.com/us/app/vicinity-lgbtq-friend-finder/id6499236231">
                      <img
                        src="/black.svg"
                        alt="Download on App Store External Link Button"
                        style={{ width: "70%" }}
                      />
                    </a>
                  </Grid>
                  <Grid item xs={12} marginTop={"40px"}>
                    <a href="https://play.google.com/store/apps/details?id=com.vicinity.vicinityApp&pcampaignid=web_share">
                      <img
                        src="/play.png"
                        alt="Download on Play Store External Link Button"
                        style={{ width: "70%", cursor: "pointer" }}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          ) : (
            <DialogContent>
              <Grid container xs={12}>
                <Grid item xs={12} padding="20px">
                  <Typography variant="h5" component="h5" paddingBottom="20px">
                    Be the first to know when Vicinity launches on Android
                  </Typography>
                  <Typography variant="body1" component="main">
                    We are in the final stages of releasing on Android. Vicinity
                    will be available (very) soon! Enter your phone number below
                    if you'd like to recieve at text when it is available.
                  </Typography>
                </Grid>
                <Grid container item spacing={2} padding="20px">
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Mobile Number"
                      InputLabelProps={{ style: { color: "black" } }}
                      variant="outlined"
                      color="primary"
                      type="phone"
                      value={phoneNumber}
                      onChange={handleInputChange}
                      error={phoneNumberError}
                      helperText={
                        phoneNumberError
                          ? "Please enter a valid phone number."
                          : ""
                      }
                      InputProps={{
                        style: { color: "black", borderColor: "#046E00" },
                        inputProps: {
                          style: { color: "black" },
                          maxLength: 12,
                        },
                      }}
                      sx={{
                        color: "black",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      disabled={submitting}
                      onClick={handleSubmit}
                      sx={{ textTransform: "none" }}
                      style={{
                        backgroundColor: "#046E00",
                        color: "white",
                        width: "100%",
                        borderRadius: "15px",
                      }}
                    >
                      {submitting ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        <Typography
                          style={{
                            fontSize: "18px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            letterSpacing: " 0.10000000149011612px",
                          }}
                        >
                          Submit
                        </Typography>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          )}
        </Dialog>
      )}

      <Box
        sx={{
          height: "100vh",
          maxHeight: isMediumUp ? "975px" : undefined,
          // maxWidth: ,
          backgroundColor: "white",
          position: "relative",

          zIndex: 1,
          backgroundImage:
            isMediumUp && !isXLargeUp ? "url('rainbow.png')" : "none",
          backgroundSize: "716px, 724px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: isLargeUp ? "bottom right" : "500px bottom",
          overflowX: isMediumUp ? "visible" : "hidden",
          overflowY: "visible",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            paddingLeft: isMediumUp ? "24px" : "0px",
            paddingRight: 0,
            paddingTop: "100px",
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              container
              xs={12}
              alignContent="flex-start"
              alignItems={isMediumUp ? "flex-start" : "center"}
              paddingLeft={isMediumUp ? "24px" : "0px"}
            >
              <Grid item container xs={12} md={6}>
                <Grid item xs={12} paddingLeft={isMediumUp ? "0px" : "15px"}>
                  <Typography
                    variant="h6"
                    component="main"
                    style={{ fontWeight: "unset" }}
                  >
                    Discover <b>new</b> LGBTQ+ <b>friends</b> around you.
                  </Typography>
                </Grid>
                <Grid item xs={12} paddingLeft={isMediumUp ? "0px" : "15px"}>
                  <img
                    alt="Vicinity Logo"
                    src="/vicinityLogo.png"
                    height="140px"
                    width="264.57px"
                    style={{ marginLeft: "-16px" }}
                  />
                </Grid>
                <Grid item xs={12} paddingLeft={isMediumUp ? "0px" : "15px"}>
                  <Typography
                    variant="h6"
                    component="main"
                    style={{ fontWeight: "unset" }}
                  >
                    No dating or hookups... <b>Our intentions are different.</b>
                  </Typography>
                  <Typography variant="body2" component="main">
                    Built for LGBTQ+ individuals, couples and groups (e.g.
                    parents) seeking friends. Vicinity actively prevents hookups
                    and intentional daters.{" "}
                    <Button
                      onClick={() => {
                        setActivePrevntionDialogOpen(true);
                      }}
                      style={{ color: "#046E00" }}
                      variant="text"
                    >
                      Learn More.
                    </Button>
                  </Typography>
                </Grid>
                <Hidden mdDown>
                  <Grid item container paddingTop="40px" paddingBottom="40px">
                    <Grid item xs={12} sm={4} spacing="2" paddingRight="10px">
                      <PlaceOutlined
                        style={{ fontSize: "60px" }}
                      ></PlaceOutlined>
                      <Typography variant="h6" component="h6">
                        User map
                      </Typography>
                      <Typography variant="body2" component="main">
                        Zoom into our dynamic User Map and watch as potential
                        friends pop up around you. Tailor your map with
                        personality filters to find individuals that match your
                        vibe.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} paddingRight="10px">
                      <ChatOutlined style={{ fontSize: "60px" }}></ChatOutlined>
                      <Typography variant="h6" component="h6">
                        Secure messaging
                      </Typography>
                      <Typography variant="body2" component="main">
                        Share your thoughts, not your privacy. Chat with the
                        local LGBTQ+ community without exposing your name, phone
                        number, or email address. It's all about fun, safe, and
                        private connections.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CalendarMonthOutlined
                        style={{ fontSize: "60px" }}
                      ></CalendarMonthOutlined>
                      <Typography variant="h6" component="h6">
                        Discover locally
                      </Typography>
                      <Typography variant="body2" component="main">
                        Connect at community events and view posts from those
                        close by. Our location-based bulletin board keeps you
                        tuned to the local buzz, helping you find friends and
                        the neighborhood's pulse.
                      </Typography>
                    </Grid>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    justifyContent="center"
                    alignItems="center"
                    paddingTop="20px"
                    paddingBottom="5px"
                    style={{
                      backgroundImage: "url('rainbow.png')",
                      backgroundSize: "auto",
                      backgroundRepeat: "no-repeat",
                      backgroundPositionX: "right",
                      backgroundPositionY: "bottom",
                    }}
                  >
                    <Carousel
                      autoPlay
                      infiniteLoop
                      showThumbs={false}
                      showArrows={true}
                      transitionTime={500}
                      interval={5000}
                      preventMovementUntilSwipeScrollTolerance={true}
                      swipeScrollTolerance={50}
                    >
                      {[1, 2, 3, 4, 5, 6].map((num) => (
                        <div key={num}>
                          <img
                            src={`/${num}.png`}
                            alt="carousel-img"
                            style={{
                              maxHeight: "650px",
                              height: "auto",
                              width: "auto",
                            }}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </Grid>
                </Hidden>
                <Hidden mdDown>
                  <Grid
                    container
                    item
                    spacing={2}
                    paddingTop="20px"
                    justifyContent="center"
                  >
                    <Grid item xs={6}>
                      {" "}
                      <a href="https://apps.apple.com/us/app/vicinity-lgbtq-friend-finder/id6499236231">
                        <img
                          src="/black.svg"
                          alt="Download on App Store External Link Button"
                          style={{ width: "75%" }}
                        />
                      </a>
                    </Grid>
                    <Grid item xs={6}>
                      <a href="https://play.google.com/store/apps/details?id=com.vicinity.vicinityApp&pcampaignid=web_share">
                        <img
                          src="/play.png"
                          alt="Download on Play Store External Link Button"
                          style={{ width: "80%", cursor: "pointer" }}
                        />
                      </a>
                    </Grid>
                  </Grid>

                  <Grid xs={4} />

                  <Grid xs={4} />
                </Hidden>
              </Grid>
              <Hidden mdDown>
                <Grid
                  container
                  item
                  xs={6}
                  justifyContent="right"
                  alignItems="right"
                  overflow="hidden"
                >
                  <div style={{ height: " 800px", width: "500px" }}>
                    <Carousel
                      autoPlay
                      infiniteLoop
                      swipeable={false}
                      showThumbs={false}
                      showArrows={true}
                      transitionTime={0}
                      interval={4000}
                      showStatus={false}
                    >
                      {[1, 2, 3, 4, 5, 6].map((num) => (
                        <div key={num}>
                          <img
                            src={`/${num}.png`}
                            alt="carousel-img"
                            style={{
                              maxHeight: "800px",
                              height: "auto",
                              width: "auto",
                            }}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid
                  item
                  container
                  bgcolor="#EFE6E6"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="40px"
                >
                  <Grid item xs={12} paddingBottom="40px">
                    <PlaceOutlined style={{ fontSize: "60px" }}></PlaceOutlined>
                    <Typography variant="h6" component="h6">
                      User map
                    </Typography>
                    <Typography
                      variant="body2"
                      component="main"
                      maxWidth="300px"
                    >
                      Zoom into our dynamic User Map and watch as potential
                      friends pop up around you. Tailor your map with
                      personality filters to find individuals that match your
                      vibe.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} paddingBottom="40px">
                    <ChatOutlined style={{ fontSize: "60px" }}></ChatOutlined>
                    <Typography variant="h6" component="h6">
                      Secure messaging
                    </Typography>
                    <Typography
                      variant="body2"
                      component="main"
                      maxWidth="300px"
                    >
                      Share your thoughts, not your privacy. Chat with the local
                      LGBTQ+ community without exposing your name, phone number,
                      or email address. It's all about fun, safe, and private
                      connections.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} paddingBottom="40px">
                    <CalendarMonthOutlined
                      style={{ fontSize: "60px" }}
                    ></CalendarMonthOutlined>
                    <Typography variant="h6" component="h6">
                      Discover locally
                    </Typography>
                    <Typography
                      variant="body2"
                      component="main"
                      maxWidth="300px"
                    >
                      Connect at community events and view posts from those
                      close by. Our location-based bulletin board keeps you
                      tuned to the local buzz, helping you find friends and the
                      neighborhood's pulse.
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid
                  container
                  item
                  spacing={2}
                  padding="20px"
                  textAlign={"center"}
                >
                  <Grid item xs={12} marginTop="40px" marginBottom="40px">
                    <a href="https://apps.apple.com/us/app/vicinity-lgbtq-friend-finder/id6499236231">
                      <img
                        src="/black.svg"
                        alt="Download on App Store External Link Button"
                        style={{ width: "60%" }}
                      />
                    </a>
                  </Grid>
                  <Grid item xs={12} marginBottom="40px">
                    <a href="https://play.google.com/store/apps/details?id=com.vicinity.vicinityApp&pcampaignid=web_share">
                      <img
                        src="/play.png"
                        alt="Download on Play Store External Link Button"
                        style={{ width: "60%", cursor: "pointer" }}
                      />
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <hr />
                  </Grid>
                  <ShareButtons />
                  <Grid xs={3} />

                  <Grid xs={3} />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={12000}
            onClose={() => {
              setSnackbarOpen(false);
            }}
          >
            <Alert
              onClose={() => {
                setSnackbarOpen(false);
              }}
              severity={snackbarSeverity}
              sx={{
                border: `4px solid ${
                  snackbarSeverity === "success" ? "#4CAF50" : "#F44336"
                }`,
              }}
            >
              {snackbarText}
            </Alert>
          </Snackbar>
        </Container>
        <Footer
          setModalOpen={() => {
            setDisclaimerModalOpen(true);
          }}
        ></Footer>
      </Box>
    </ThemeProvider>
  );
}

async function addPhoneNumber(phoneNumber: string) {
  const url =
    "https://4bh1ljnpvi.execute-api.us-east-2.amazonaws.com/dev/addPhone";
  const body = {
    phoneNumbers: [phoneNumber.replaceAll("-", "")],
    messageType: "welcome",
  };

  try {
    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Origin: window.location.origin,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error adding phone number:", error);
    throw error;
  }
}
