import axios from "axios";
import { auth } from "../firebase";
import { EventPost } from "../routes/Admin/Events";
import { formatPost } from "../routes/Admin/EventUtils";
import { GeoPoint } from "firebase/firestore";
import ReactQuill from "react-quill";

const baseUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://us-central1-vicinity-e7a18.cloudfunctions.net"
    : "https://us-central1-vicinitytest-523d5.cloudfunctions.net";

export const fetchPosts = async (
  isEvent?: boolean,
  userId?: string,
  displayName?: string
): Promise<EventPost[]> => {
  try {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      const res = await axios.get<EventPost[]>(`${baseUrl}/fetchPosts`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        params: {
          userId,
          isEvent: isEvent ?? false,
          displayName: displayName ?? undefined,
        },
      });
      const eventPosts: EventPost[] = res.data.map((post: EventPost) => {
        return {
          id: post.id,
          title: post.title,
          content: post.content,
          dateCreated: post.dateCreated,
          place: post.place,
          placeAddress: post.placeAddress,
          imageUrl: post.imageUrl,
          startTime: post.startTime,
          endTime: post.endTime,
          userId: post.userId,
          isEvent: post.isEvent,
          location: post.location,
        };
      });
      const final = eventPosts.map(formatPost);
      return final;
    }
    return [];
  } catch (error) {
    console.error("Error fetching posts", error);
    return [];
  }
};

export const handleAddPost = async (
  post: EventPost,
  wysiwygMode: boolean,
  quillRef: React.RefObject<ReactQuill>,
  fetchPosts: () => Promise<void>,
  setMessage: (message: string) => void,
  setLoading: (loading: boolean) => void,
  vicinityUserId?: string
) => {
  setLoading(true);
  try {
    const user = auth.currentUser;
    if (user) {
      console.log("post before submit", post);
      const idToken = await user.getIdToken();
      const postToSubmit = {
        ...post,
        content:
          wysiwygMode && quillRef?.current?.getEditor()
            ? JSON.stringify(quillRef?.current?.getEditor().getContents())
            : post.content,
        userId: vicinityUserId,
        startTime: new Date(post.startTime),
        endTime: post.endTime ? new Date(post.endTime) : null,
        dateCreated: new Date(),
        location: {
          geohash: post.location.geohash,
          geopoint: new GeoPoint(
            post.location.geopoint._latitude,
            post.location.geopoint._longitude
          ),
        },
      };
      console.log("postToSubmit", postToSubmit);
      const res = await axios.post<{ id: string }>(
        `${baseUrl}/addEventPost`,
        postToSubmit,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setMessage(`Post added with ID: ${res.data.id}`);

      fetchPosts(); // Refresh posts list
    }
  } catch (error) {
    setMessage("Error adding post");
  } finally {
    setLoading(false);
  }
};

export const handleEditPost = async (
  post: EventPost,
  wysiwygMode: boolean,
  quillRef: React.RefObject<ReactQuill>,
  editingPostId: string | null,
  fetchPosts: () => Promise<void>,
  setMessage: (message: string) => void,
  setLoading: (loading: boolean) => void,
  vicinityUserId?: string
) => {
  setLoading(true);
  try {
    const user = auth.currentUser;
    if (user && editingPostId) {
      console.log("post before submit", post);
      const idToken = await user.getIdToken();
      const postToSubmit = {
        ...post,
        content:
          wysiwygMode && quillRef?.current?.getEditor()
            ? JSON.stringify(quillRef?.current?.getEditor().getContents())
            : post.content,
        userId: vicinityUserId,
        startTime: new Date(post.startTime),
        endTime: post.endTime ? new Date(post.endTime) : null,
        location: {
          geohash: post.location.geohash,
          geopoint: new GeoPoint(
            post.location.geopoint._latitude,
            post.location.geopoint._longitude
          ),
        },
      };
      delete postToSubmit.dateCreated;
      console.log("postToSubmit", postToSubmit);
      const res = await axios.post<{ id: string }>(
        `${baseUrl}/addEventPost`,
        postToSubmit,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setMessage(`Post updated successfully: ${res.data.id}`);
      fetchPosts(); // Refresh posts list
    }
  } catch (error) {
    setMessage("Error updating post");
  } finally {
    setLoading(false);
  }
};

export const handleDeletePost = async (
  postId: string,
  setLoading: (loading: boolean) => void,
  setMessage: (message: string) => void,
  fetchPostsWrapper: () => void,
  setDeleteId: (id: string) => void
) => {
  setLoading(true);
  try {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      await axios.post(
        `${baseUrl}/deleteEventPost`,
        { id: postId },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setMessage("Post deleted successfully");
      setDeleteId("");
      fetchPostsWrapper(); // Refresh posts list
    }
  } catch (error) {
    setMessage("Error deleting post");
  } finally {
    setLoading(false);
  }
};

export const fetchVicinityUserId = async (): Promise<string> => {
  try {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      const res = await axios.get<{ userId: string }>(
        `${baseUrl}/fetchVicinityAccountUserId`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return res.data.userId;
    }
    return "";
  } catch (error) {
    console.error("Error fetching vicinity user ID", error);
    return "";
  }
};

export const sendNotificationToolNotification = async (
  title: string,
  message: string,
  centerPoint: { lat: number; lng: number } | null,
  radius: number,
  postId: string,
  bypassNotificationDocument: boolean
): Promise<{ successes: number; failures: number }> => {
  console.log(
    title,
    message,
    centerPoint,
    radius,
    postId,
    bypassNotificationDocument
  );
  try {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      const response = await axios.post(
        `${baseUrl}/sendNotificationToolPushNotification`,
        {
          title,
          message,
          centerPoint: centerPoint ?? undefined,
          radiusInMiles: radius ?? undefined,
          postId: postId?.length > 0 ? postId : undefined,
          bypassNotificationDocument: bypassNotificationDocument ?? false,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return {
        successes: response.data.successes,
        failures: response.data.failures,
      };
    }
    throw new Error("User not authenticated");
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchSentNotifications = async (
  userId: string
): Promise<any[]> => {
  try {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${baseUrl}/getSentNotifications`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        params: {
          userId,
        },
      });
      return response.data.notifications;
    }
    throw new Error("User not authenticated");
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const analyzeNotification = async (
  adminNotificationTracingId: string
): Promise<{ numberOpened: number; numberSent: number }> => {
  try {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      const response = await axios.get(
        `${baseUrl}/getSentNotificationAnalysis`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
          params: {
            adminNotificationTracingId,
          },
        }
      );
      return response.data;
    }
    throw new Error("User not authenticated");
  } catch (error: any) {
    throw new Error(error.message);
  }
};
